import React, { createContext } from 'react';
import { msalConfig } from './MsalConfig';
import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import { getOrganizationDetails } from '../graph/GraphService';
import * as Utils from '../utils/helper';
import { ErrorHandlingContext } from '../context/ErrorHandlingContext';
import { TokenRetrievalError } from '../utils/errors';
import { marketPlaceHelper } from '../utils/marketPlaceHelper';
import { appConfig } from '../logic/appConfigProvider';
import { urls } from '../logic/urls';
import { urlCombine, getBaseUriWithoutParameters } from '../utils/misc';

 
const isProvidingAdminConsentKey = 'isProvidingAdminConsent';
const adminConsentStateKey = 'adminConsentState';
const userIdTokenClaimsKey = 'userIdTokenClaimsKey';

const cybergateBotAppId = appConfig.azureActiveDirectoryClientId;

// Enum defines the possible states of the authenticationState property of the state of the AuthenticationProvider.
export const authenticationStates = {
    NOT_AUTHENTICATED: 'NotAuthenticated', // No user is currently logged-in
    AUTHENTICATING: 'Authenticating', // The redirect back from Microsoft has taken place, but the process of exchanging the authentication code for the tokens is still in progress.
    AUTHENTICATED: 'Authenticated', // The user is successfully logged in. The first time this state is reached, the check for the authorization will be started.    
}

// Enum defines the possible states of the authorizationState property of the state of the AuthenticationProvider.
export const authorizationStates = {
    UNKNOWN: 'Unknown', // The authorization has not been checked. This check is triggered when the authentication state reaches the AUTHENTICATED state.
    AUTHORIZED: 'Authorized', // The currently logged-in user is authorized to use this application. This means that the tenant has been signed up. No user-based checks are currently performed.
    UNAUTHORIZED: 'Unauthorized' // The currently logged-in user uis not authorized to use this application.
}


export const AuthenticationContext = createContext();

const loginScopes = [".default"];

export class AuthenticationProvider extends React.Component {
    static contextType = ErrorHandlingContext;

    constructor(props) {
        super(props);

        this.msalAuth = new PublicClientApplication(msalConfig);
        const userAccount = this.getUserAccount();

        // After startup of the application it is not immediately known if there is a logged-in user or not, because the PublicClientApplication
        // might still be busy with requesting an access token based upon the received authorization code. Only when the handleRedirectPromise
        // has been invoked, the logged-in accounts can be queried reliably. 
        // There seems to be no way in the MSAL library to query if this work is still in progresss or not. This variable is a work-around 
        // to have this state available.
        this.isInProgress = true;

        this.state = {
            getAuthenticationState: () => { return this.getAuthenticationState() },
            authorizationState: authorizationStates.UNKNOWN,
            detailedAuthorizationState: {},
            isAuthenticated: () => { return this.getAuthenticationState() === authenticationStates.AUTHENTICATED},
            isAuthorized: () => { return this.state.authorizationState === authorizationStates.AUTHORIZED },            
            user: userAccount,
            userIdTokenClaims: this.retrieveUserIdTokenClaims(),            
            login: () => { 
                this.clearSessionStorage();                

                const accessTokenRequest = {
                    scopes: loginScopes,                    
                    prompt: "select_account"
                }

                this.msalAuth.loginRedirect(accessTokenRequest);
            },
            logout: () => {
                this.clearSessionStorage();

                const redirectUrl = urlCombine(getBaseUriWithoutParameters(), urls.home);

                const request = {
                    account: this.getUserAccount(),
                    postLogoutRedirectUri: redirectUrl
                }

                this.msalAuth.logoutRedirect(request);
            },
            loginFromMarketplace: () => {
                this.clearSessionStorage();

                marketPlaceHelper.setMarketPlaceLogin();
                
                const accessTokenRequest = {
                    scopes: loginScopes,                    
                    prompt: "select_account"
                }

                this.msalAuth.loginRedirect(accessTokenRequest);                
            },
            redirectToAdminConsent: () => {
                this.clearSessionStorage();

                const redirectUri = document.getElementById('root').baseURI + 'post-consent-spa';
                const state = "LDRDEMF27QEVFT6UYI9J" // ToDo: Generate a random string.
                
                sessionStorage.setItem(isProvidingAdminConsentKey, true);
                sessionStorage.setItem(adminConsentStateKey, state);
                const adminConsentUri = this.constructAdminConsentUrl(redirectUri, state);

                window.location.href = adminConsentUri;
            },
            getApiTokenAsync: async () => {
                const accessTokenRequest = {
                    scopes: [appConfig.frontendMsalApiScope],
                    account: this.state.user
                }

                try {
                    console.log("[AuthenticationProvider, getApiTokenAsync] getApiTokenAsync invoked...");

                    const accessToken = await this.msalAuth.acquireTokenSilent(accessTokenRequest);               

                    console.log("[AuthenticationProvider, getApiTokenAsync] Accesstoken for back-end API successfully retrieved!");
                    //console.log(JSON.stringify(accessToken.accessToken))

                    return accessToken;
                }
                catch (err) {
                    console.log(`[AuthenticationProvider, getApiTokenAsync] Error occurred while retrieving token for back-end API: ${err}`);

                    if (err instanceof InteractionRequiredAuthError) {
                        console.log("[AuthenticationProvider, getApiTokenAsync] Starting interactive attempt with redirect...");
                        this.msalAuth.acquireTokenRedirect(accessTokenRequest);                        
                        return;
                    } 
                    
                    throw new TokenRetrievalError(err, "Error occurred while retrieving token for back-end API");                   
                }
            },
            getGraphApiTokenAsync: async (executionCount = 0) => {
                const accessTokenRequest = {
                    scopes: ["https://graph.microsoft.com/User.Read"],
                    account: this.state.user
                }

                try {
                    console.log("[AuthenticationProvider, getGraphApiTokenAsync] getGraphApiTokenAsync invoked...");
        
                    const accessToken = await this.msalAuth.acquireTokenSilent(accessTokenRequest);
                    console.log("[AuthenticationProvider, getGraphApiTokenAsync] Accesstoken for the Graph API successfully retrieved!");
                    //console.log(accessToken.accessToken);

                    return accessToken;
                }
                catch (err) {
                    console.log(`[AuthenticationProvider, getGraphApiTokenAsync] Error occurred while retrieving token for Graph API: ${err}`);

                    if (err instanceof InteractionRequiredAuthError) {

                        // Graph token requests can fail for quit a while (45s seen during testing) after consent has been given.
                        //  Wait a maximum 2 minutes (24 x 5s) for a graph token request to succeed.
                        if (executionCount < 24) { 
                            console.log(`[AuthenticationProvider, getGraphApiTokenAsync] Error occurred while retrieving token for Graph API: ${err}. Retrying (${executionCount})...`);
                            await new Promise(r => setTimeout(r, 5000));
                            return this.state.getGraphApiTokenAsync(executionCount + 1);
                        }

                        console.log("[AuthenticationProvider, getGraphApiTokenAsync] Starting interactive attempt with redirect...");
                        this.msalAuth.acquireTokenRedirect(accessTokenRequest);
                        return;
                    }                  
                    
                    throw new TokenRetrievalError(err, "Error occurred while retrieving token for Graph API");    
                }
            }
        };
    }
    
        /// Method returns the user account object of the logged-in user or null if the user is not logged-in.
    getUserAccount = () => {
        return this.msalAuth.getActiveAccount();
    }

    /// Method returns the current authentication state which is either NOT_AUTHENTICATED, AUTHENTICATING or AUTHENTICATED.
    getAuthenticationState = () => {
        if (this.getUserAccount()) {
            return authenticationStates.AUTHENTICATED; 
        }

        if (this.isInProgress) {
            return authenticationStates.AUTHENTICATING;
        }   
        
        return authenticationStates.NOT_AUTHENTICATED;
    }

    // Method stores the provided user Id token claims in the session storage.
    // These user Id token claims are received in the redirect callback of the MSAL library and are not part of the account information and cannot be retrieved from the 
    // MSAL library. For this reason these claims are persisted in the session storage so they can be used when the page is refreshed to set the initial state of the 
    // authenticationContext correctly.
    persistUserIdTokenClaims = (userIdTokenClaims) => {
        const serialized = JSON.stringify(userIdTokenClaims);
        sessionStorage.setItem(userIdTokenClaimsKey, serialized);
    }

    // Method retrieves the persisted user Id token claims from the session storage.
    // In case no user is logged-in, null is returned.
    retrieveUserIdTokenClaims = () => {
        const serialized = sessionStorage.getItem(userIdTokenClaimsKey);

        if (!serialized) {
            return null;
        }

        const userIdTokenClaims = JSON.parse(serialized);
        return userIdTokenClaims;
    }

    // Method clears all information that is persisted in the session storage by this module.
    clearSessionStorage = () => {    
        marketPlaceHelper.clearMarketPlaceLogin();
        sessionStorage.removeItem(isProvidingAdminConsentKey);
        sessionStorage.removeItem(userIdTokenClaimsKey);
    }

    isEmptyOrSpaces = (str) => {
        return !str || typeof str !== "string" || str.match(/^ *$/) !== null;
    }

    // Method performs a best-effort attempt to extract the organization name
    // from the provided username.
    extractOrganizationNameFromUsername = (fullName) => {
        if (this.isEmptyOrSpaces(fullName)) {
            return "";
        }

        const atSignPosition = fullName.indexOf('@');
        if (atSignPosition === -1) {
            // This does not look like an e-mail address, return the full string instead.
            return fullName;
        }

        // Extract everything after the '@' sign.
        const fullDomain = fullName.substring(atSignPosition + 1);

        // The default domain name when an Azure account is created is '<customername>.onmicrosoft.com'.
        // When this pattern is recognized, the 'customername' part is extracted and returned.
        const microsoftDefaultDomain = '.onmicrosoft.com';
        if (fullDomain.toLowerCase().endsWith(microsoftDefaultDomain)) {
            return fullDomain.substring(0, fullDomain.length - microsoftDefaultDomain.length);
        }

        // The customer has added its custom domain to its Azure account, so the fullDomain contains 
        // a domain like 'contoso.com'. Try to remove the top-level domain ('.com') and return the result.
        const lastDotPosition = fullDomain.lastIndexOf('.');
        if (lastDotPosition === -1) {
            // No top-level domain found, return the full domain.
            return fullDomain;
        }

        // Return everything up to the last dot.
        return fullDomain.substring(0, lastDotPosition);
    }

    // Method ensures the authorizationState is set to UNKNOWN and the persisted cached value is cleared.
    clearAuthorizationState = () => {
        // Ensure the current authorization state is UNKNOWN.
        if (this.state.authorizationState !== authorizationStates.UNKNOWN) {
            console.log(`[AuthenticationProvider, clearAuthorizationState] User not authenticated, setting authorizationState to UNKNOWN. Current state: ${this.state.authorizationState}`); 
            this.setState({
                authorizationState: authorizationStates.UNKNOWN,
                detailedAuthorizationState: {}
            });
        }         

        return;
    }

    // Method determines and sets the authorizationState property of the state. 
    // In case no user is logged-in, the state will be set to UNKNOWN.
    // In case a user is logged-in:
    //  - If this user was redirected from the Marketplace, the tenant is added to the administration in the back-end and the state is set to AUTHORIZED. 
    //  - Else perform the actual check in the back-end if this user is authorized. This either returns AUTHORIZED or UNAUTHORIZED.
    updateAuthorizationState = async () => {        
        
        // The authorization check should only be performed when the user is successfully logged-in.
        if (this.getAuthenticationState() !== authenticationStates.AUTHENTICATED) {
            // Unless the user is currently logging in, clear the authorization state.
            if (this.getAuthenticationState() !== authenticationStates.AUTHENTICATING) {
                this.clearAuthorizationState();
            }
            
            return;
        }

        // Verify that the organization is already signed-up and that the user is authorized (is a valid  administrator ('global', 'application', or 'cloud application')) to use this application. 
        console.log(`[AuthenticationProvider, updateAuthorizationState] User is authenticated, now checking for authorization...`);            
        let authorizationInfo = await this.checkAuthorizationOnBackend();

        // Sign-up the tenant in case the organization is not authorized, the user is authorized and the user has entered the application via the landingpage.
        // The MarketPlace has added a token in the Landingspage URL (which can be resolved at the MarketPlace to the subscription). 
        // As a safety measurement a tenant can only be added when also a valid MarketPlace token is added in the same request. This prevents 
        // random tenants can be added.
        if (!Utils.isTrue(authorizationInfo.isOrganizationAuthorized) &&
            Utils.isTrue(authorizationInfo.isUserAuthorized) &&
            marketPlaceHelper.isMarketPlaceLogin()) {                            
            console.log(`[AuthenticationProvider, updateAuthorizationState] Tenant is not authorized, user is authorized and the login was triggered by the landingpage. Creating the administration in the back-end...`);
            
            const marketPlaceToken = marketPlaceHelper.getMarketplaceToken();

            const wasSignupSuccessful = await this.signUpTenant(marketPlaceToken);                    
            if (!wasSignupSuccessful) {
                return; // Error overlay will be shown automatically.
            }

            // Perform the authorization check again on the back-end.
            authorizationInfo = await this.checkAuthorizationOnBackend();
        }

        const authorizationState = Utils.isTrue(authorizationInfo.isOrganizationAuthorized) && Utils.isTrue(authorizationInfo.isUserAuthorized) ? 
                                    authorizationStates.AUTHORIZED : 
                                    authorizationStates.UNAUTHORIZED;
        console.log(`[AuthenticationProvider, updateAuthorizationState] Authorization state: ${authorizationState}`);    
                
        this.setState({
            authorizationState: authorizationState,
            detailedAuthorizationState: authorizationInfo                
        });
    }

    async componentDidMount() {
        console.log("[AuthenticationProvider, componentDidMount] Method invoked...");

        this.msalAuth.initialize().then(() => {
            // Register for the callbacks from the MSAL library.
            // The success callback ('then' part) is invoked immediately after registration when the user is either not authorized or already authorized. 
            // In that case no token is provided as parameter.
            // When the application is starting up because of a redirect back from Azure, the MSAL library will start to resolve the OAuth authorization code for
            // an id_token and an access_token. When this process is finished the 'then' part is invoked and the tokenResponse parameter will contain 
            // all details from the response from Azure AD.
            this.msalAuth.handleRedirectPromise().then(async (tokenResponse) => {
                this.isInProgress = false;
                if (tokenResponse === null) {
                    console.log("[handleRedirectPromise] No change in logged-in state of user.");
                    await this.updateAuthorizationState();
                    return;     
                }
        
                let account = tokenResponse.account;
                console.log(`[handleRedirectPromise] User '${account.username}' successfully logged in.`);
                
                this.msalAuth.setActiveAccount(account);
        
                this.persistUserIdTokenClaims(tokenResponse.idTokenClaims);            
                this.setState({
                    user: tokenResponse.account,
                    userIdTokenClaims: tokenResponse.idTokenClaims                
                });
        
                await this.updateAuthorizationState();
        
                return;
            }).catch((error) => {
                // Error occurred during log-in...
                this.isInProgress = false;
                console.log(`[handleRedirectPromise] ${error.message}`);
                
                this.msalAuth.setActiveAccount(null);
            });
        });
    }  

    constructAdminConsentUrl = (redirectUri, state) => {
        const redirectUriEncoded = encodeURI(redirectUri);
        const stateEncoded = encodeURI(state);        
        const clientId = cybergateBotAppId;

        return `https://login.microsoftonline.com/common/adminconsent?client_id=${clientId}&redirect_uri=${redirectUriEncoded}&state=${stateEncoded}`;
    }
    
    // Method retrieves the authorization information from the back-end.
    // Response looks like this:
    // {
    //   "isOrganizationAuthorized": true,
    //   "isUserAuthorized": true
    // }
    checkAuthorizationOnBackend = async () => {
        try {
            console.log("[AuthenticationProvider, checkAuthorizationOnBackend] Checking the authorization of the user on the back-end...");

            const accessToken = await this.state.getApiTokenAsync();

            const response = await fetch('api/tenant/authorizePortal', {
                headers: { 'Authorization': `Bearer ${accessToken.accessToken}` }
            });

            if (response.status === 200) {
                // A success response is received from the back-end. Verify the tenant is enabled.                
                const data = await response.json();

                // The response from the back-end contains a flag that indicates if the current tenant has the authorization to use this application.
                return data;
            }

            // All other response from the API are handled as an unexpected error.
            throw new Error(`Server returned unexpected status code ${response.status}`);
        }
        catch (err) {
            this.context.setError("Could not verify user authorization at the server.", err);            
        }

        return {};
    }

    // Method attempts to retrieve the organization display name for the currently logged-in user from the Graph API.
    // Testing showed that this often fails when the user has just given consent (which always happens when the 
    // tenant reaches the management portal for the first time). In that case the AccessToken for the Graph API is 
    // successfully retrieved, but does not contain the 'User.Read' scope. It takes more than 15 seconds before the 
    // consent is fully processed at Microsoft and a complete AccessToken is returned. This is too long to have 
    // the user waiting so a fallback is implemented. 
    // This fallback is to retrieve the organization name from the username using the 'extractOrganizationNameFromUsername' 
    // method.
    getOrganizationDisplayName = async () => {
        try {
            console.log("[getOrganizationDisplayName] Invoking the Graph API to get the organization name of this tenant.");

            const accessToken = await this.state.getGraphApiTokenAsync();
            var organization = await getOrganizationDetails(accessToken);

            if (organization && organization.value && organization.value.length > 0) {
                return organization.value[0].displayName;
            }

            console.log(`[getOrganizationDisplayName] Error: Could not extract the organization name from the response: ${JSON.stringify(organization)}`);
        }
        catch (err) {
            // The global error is not set here, because failure of retrieving the organization name should not 
            // break the sign-up process.
            console.log(`[getOrganizationDisplayName] Error while retrieving organization name for tenant: ${JSON.stringify(err)}`);
        }

        // In case the displayname of the tenant could not be retrieved, perform a best-effort attempt to get the 
        // organization name from the full username.
        if (this.getUserAccount()) {
            console.log("[getOrganizationDisplayName] Organization name could not be retrieved from the Graph API, now extracting the name from the full username.");
            return this.extractOrganizationNameFromUsername(this.getUserAccount().username);
        }

        return "Unknown";
    }

    signUpTenant = async (marketPlaceTokenValue) => {
        try {
            console.log("[AuthenticationProvider] signUpTenant invoked.");

            // Retrieve the tenant display name for the logged-in user from the Graph API. 
            // Note: With the User.Read rights (which should be granted for this app), also a limited set
            // of information about the organization can be retrieved.
            const tenantDisplayName = await this.getOrganizationDisplayName();

            // Create the (partial) TenantInfo object which is sent in a POST request to create the initial Tenant info.
            const tenantInfo = {
                tenantLabel: tenantDisplayName
            }

            const addTenantUrl = `/api/tenant?token=${encodeURIComponent(marketPlaceTokenValue)}`;

            const accessToken = await this.state.getApiTokenAsync();
            
            const response = await fetch(addTenantUrl, {
                    method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken.accessToken}`,
                    'Content-Type': 'application/json'
                },
                    body: JSON.stringify(tenantInfo)
                }
            );

            console.log(`Response code from POST request for adding tenant: ${response.status} ${response.statusText}`);     
            if (response.status >= 200 && response.status < 300) {
                return true;
            } else if (response.status === 409) {
                // The tenant already exists in the back-end. This is an exception because this method is only executed when the tenant is not authorzed,
                // which normally means the tenant does not exist in the database. One exception is however when this tenant has manually been set to disabled.
                console.log(`Tenant with Id ${this.getUserAccount().tenantId} already exist. Returning True as if the sign-up was successful.`);                
                return true;
            } else {
                throw new Error(`Sign-up of tenant failed with response code ${response.status}`);
            }     
        }
        catch (err) {
            this.context.setError("Error occurred during sign-up of tenant.", err);            
        }
      
        return false;
    }

    render() {
        return (
            <AuthenticationContext.Provider value={this.state}>
                {this.props.children}
            </AuthenticationContext.Provider>
        )
    }  
}