import { appConfig } from '../logic/appConfigProvider';
import { urls } from '../logic/urls';
import { urlCombine, getBaseUriWithoutParameters } from '../utils/misc';

export const msalConfig = {
    auth: {
        authority: 'https://login.microsoftonline.com/common',
        clientId: appConfig.frontendMsalClientId,
        navigateToLoginRequestUrl: false,
        redirectUri: urlCombine(getBaseUriWithoutParameters(), urls.postLogin)
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
    },
    system: {
        loggerOptions: {
            loggerCallback: (
                level,
                message,
                containsPii
            ) => {
                if (containsPii) {
                    return;
                }
                
                console.info(message);
            },
            piiLoggingEnabled: false,
        }
    }
}