import { Component } from 'react';
import { Button } from 'reactstrap';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import * as misc from '../../../utils/misc';
import { ButtonWithDisabledTooltip } from '../../../components/ButtonWithDisabledTooltip';

type TenantAuditTrailConfigProps = {
    tenantInfo: any,
    onConfigChange: any,
}

type TenantAuditTrailConfigState = {
    newAuditTrailRetentionMonths: number,
    showAuditTrailModal: boolean,
}

export class TenantAuditTrailConfig extends Component<TenantAuditTrailConfigProps, TenantAuditTrailConfigState> {
    state: TenantAuditTrailConfigState  = {
        newAuditTrailRetentionMonths: 12,
        showAuditTrailModal: false,
    }
    

    async componentDidMount() {

    }

    showAuditTrailModal = () => {
        const currentRetentionMonths = this.getCurrentRetentionMonths();

        this.setState({
            showAuditTrailModal: true,
            newAuditTrailRetentionMonths: currentRetentionMonths,
        });
    };

    hideAuditTrailModal = () => {
        this.setState({
            showAuditTrailModal: false,
        });
    };

    executeAuditTrailConfigChange = () => {
        const retentionDays = misc.monthsToDays(this.state.newAuditTrailRetentionMonths)

        this.props.onConfigChange(retentionDays);

        this.hideAuditTrailModal();
    };

    handleRetentionChanged = (e : React.ChangeEvent<HTMLInputElement>) => {
        let newRetention = Number(e.target.value);

        if (newRetention < 1) {
            newRetention = 1;
        }

        if (newRetention > 12) {
            newRetention = 12;
        }

        this.setState({
            newAuditTrailRetentionMonths: newRetention
        });
    }

    getUpdateButtonTooltip = () => {
        if (this.getCurrentRetentionMonths() === this.state.newAuditTrailRetentionMonths) {
            return "No changes detected";
        }

        return null;    
    }

    updateButtonEnabled = () => {
        return this.getUpdateButtonTooltip() === null;
    }

    getCurrentRetentionMonths = () => {
        return misc.daysToMonths(this.props.tenantInfo.auditTrailRetentionDays);
    }

    render() {

        const AuditTrailSettingsModalRendered = <>
            <Modal style={{ opacity: 1 }} show={this.state.showAuditTrailModal} onHide={this.hideAuditTrailModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Audit trail settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant='warning' style={{ opacity: 1 }}>
                        Notice: Updating the retention setting can take up to an hour to take effect. Decreasing the retention time will lead to removal of trail entries older than the new retention time.
                    </Alert>
                    <Form className="mt-3" onSubmit={e => e.preventDefault()}>
                        <Form.Group controlId="ssetAuditTrailRetention">
                            <Form.Label>Audit trail retention in Months</Form.Label>
                            <Form.Control type="number" value={this.state.newAuditTrailRetentionMonths} onChange={this.handleRetentionChanged}/>
                            <Form.Text className="text-muted">
                                Minimum 1 month, maximum 12 months 
                            </Form.Text>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button color="secondary" onClick={this.hideAuditTrailModal}>
                        Cancel
                    </Button>
                    <ButtonWithDisabledTooltip color="primary" disabled={!this.updateButtonEnabled()} disabledTooltip={this.getUpdateButtonTooltip()} onClick={this.executeAuditTrailConfigChange}>
                        Update
                    </ButtonWithDisabledTooltip>
                </Modal.Footer>
            </Modal>
        </>

        const headerRendered = 
            <div>
                <h4>
                    Audit Trail
                </h4>
            </div>

       
        // Determine the main content.
        const configRendered = <div>
            <p>Retention is set to {this.getCurrentRetentionMonths()} months</p>
            <Button style={{width: '130px'}}  className="mb-3" color="primary" onClick={this.showAuditTrailModal}>Change settings</Button>
        </div>

        const openButtonRendered = <Link to='/portal/audit-trail'><Button style={{width: '130px'}} className="mb-3" color="primary">Show audit trail</Button></Link>
        
        const AuditTrailSectionRendered =  
            <div>
                {headerRendered}                          
                {configRendered}
                {openButtonRendered}
            </div>

        return (
            <div>
                {AuditTrailSettingsModalRendered}
                {AuditTrailSectionRendered}
            </div>
            );
    }
}