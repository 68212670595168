import React, { Component, useContext } from 'react';
import { ActivityMeasurement } from './ActivityMeasurement';
import { AuthenticationContext } from '../msal/AuthenticationContext';


const logoutTimeoutMs = 15 * 60 * 1000;

type AutoLogoffInternalProps = {
    authenticationContext: any,
    children?: React.ReactNode
};

export class AutoLogoffInternal extends Component<AutoLogoffInternalProps, {}> {
    handleActivityStateChange = (isActive: boolean) => {
        if (!isActive) {
            this.props.authenticationContext.logout();
        }
    }

    render() {
        return (
            <ActivityMeasurement initialIsActive={true} onActivityStateChange={this.handleActivityStateChange} onSurfaceActivityTimeoutMs={logoutTimeoutMs} offSurfaceActivityTimeoutMs={logoutTimeoutMs}>
                {this.props.children}
            </ActivityMeasurement>
        )
    }
}

// Inject the required contexts.
export const AutoLogoff = (props: any) => {    
    const authenticationContext = useContext(AuthenticationContext);

    return (
        <AutoLogoffInternal {...props} authenticationContext={authenticationContext} />
    )
}