import { Component } from 'react';
import { Spinner as BootstrapSpinner } from 'react-bootstrap';

type SpinnerState = {
    waitingText: string | undefined,
}

export class Spinner extends Component<{}, SpinnerState> {
    waitingTakesLongerTimer : NodeJS.Timeout | undefined;

    state : SpinnerState = {
        waitingText: undefined, 
    }

    componentDidMount() {
        clearInterval(this.waitingTakesLongerTimer);
        this.waitingTakesLongerTimer = setInterval(() => {
            this.setState({
                waitingText: 'Waiting takes longer than expected, please have patience...',
            });

            this.waitingTakesLongerTimer = setInterval(() => {
                this.setState({
                    waitingText: 'Wow, this takes so long! Please have some more patience...',
                });

                this.waitingTakesLongerTimer = setInterval(() => {
                    this.setState({
                        waitingText: 'Still not done? Impressed by your patience...',
                    });
                }, 20000);
            }, 10000);
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.waitingTakesLongerTimer);
    }

    render() {
        const waitingTakesLongerRendered = this.state.waitingText ? 
            <div className="mt-3 d-flex justify-content-center">
                <p>{this.state.waitingText}</p>
            </div> : <></>

        return (
            <>
                <div className="mt-3 d-flex justify-content-center">
                    <BootstrapSpinner animation="border" role="status" />
                </div>
                {waitingTakesLongerRendered}
            </>
        );
    }
};