import { useContext, Component } from 'react';
import { Container } from 'reactstrap';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { AuthenticationContext } from '../../../msal/AuthenticationContext';
import { NotificationContext } from '../../../context/NotificationContext';
import { TenantContext } from '../../../context/TenantContext';
import { AdminConsent } from '../../../components/AdminConsent';
import { TenantCallForwardingConfig } from '../../../components/TenantCallForwardingConfig';
import { TenantTrunkConfig } from '../../../components/TenantTrunkConfig';
import { TenantSecurityPoliciesConfig } from '../../../components/TenantSecurityPoliciesConfig';
import { TenantAuditTrailConfig } from './TenantAuditTrailConfig';
import { TenantRecordingConfig } from '../../../components/TenantRecordingConfig';
import { TenantCommunicationTestScriptDownload } from '../../../components/TenantCommunicationTestScriptDownload';
import * as misc from '../../../utils/misc';
import * as apiCalls from '../../../logic/apiCalls';

class GlobalSettingsInternal extends Component {
    state = {
        wanIpAddress: null,
        isLoading: true,
    }

    // Get the tenant info and WAN IP address when the page is loaded.    
    async componentDidMount() {
        await this.props.tenantContext.refreshTenant();
        this.setState({
            isLoading: false,
        });
    }

    executeTrunkSupportChange = () => {
        console.log(`Change trunk support`);

        const newTrunkSupportEnabledSetting = !this.props.tenantContext.trunkSupportEnabled;

        this.executeSaveTrunkSupport(newTrunkSupportEnabledSetting);
    }

    executeSecureOnlyPolicyChange = () => {
        console.log(`Change secure-only policy`);

        const newSecureOnlyPolicyEnabledSetting = !this.props.tenantContext.secureOnlyPolicyEnabled;

        this.executeSaveSecureOnlyPolicy(newSecureOnlyPolicyEnabledSetting);        
    }

    executeCallRecordingChange = () => {
        console.log(`Change call recording`);

        const newCallRecordingEnabledSetting = !this.props.tenantContext.callRecordingEnabled;

        this.executeSaveCallRecording(newCallRecordingEnabledSetting);
    }

    executeAuditTrailConfigChange = (newAuditTrailRetentionDays) => {
        console.log(`Change audit trail config`);

        this.executeSaveAuditTrailRetention(newAuditTrailRetentionDays);
    }

    executeSaveCallForwarding = async (callForwardingEnabled) => {
        try
        {
            await apiCalls.tenantUpdateCallForwarding(this.props.authenticationContext,
                callForwardingEnabled);

            this.props.notificationContext.setNotification(`Updating tenant.`, `Successfully updated tenant with call forwaring '${callForwardingEnabled ? "enabled":"disabled"}'.`, 'success');
            this.props.tenantContext.refreshTenant();
            this.setState({
                newWanIpAddressValue: ""
            });
        }
        catch (err) {
            this.props.notificationContext.setNotification(`Updating tenant.`, `Error updating tenant with call forwaring '${callForwardingEnabled ? "enabled":"disabled"}'. ${err.toDetailedMessage()}`, 'danger');
        }
    }

    executeSaveTrunkSupport = async (trunkSupportEnabled) => {
        try
        {
            await apiCalls.tenantUpdateTrunkSupport(this.props.authenticationContext,
                trunkSupportEnabled);

            this.props.notificationContext.setNotification(`Updating tenant.`, `Successfully updated tenant with SIP trunk support '${trunkSupportEnabled ? "enabled":"disabled"}'.`, 'success');
            this.props.tenantContext.refreshTenant();
            this.setState({
                newWanIpAddressValue: ""
            });
        }
        catch (err) {
            this.props.notificationContext.setNotification(`Updating tenant.`, `Error updating tenant with SIP trunk support '${trunkSupportEnabled ? "enabled":"disabled"}'.  ${err.toDetailedMessage()}`, 'danger');
        }
    }

    executeSaveSecureOnlyPolicy = async (secureOnlyPolicyEnabled) => {
        try
        {
            await apiCalls.tenantUpdateSecureOnlyPolicy(this.props.authenticationContext,
                secureOnlyPolicyEnabled);

            this.props.notificationContext.setNotification(`Updating tenant.`, `Successfully updated tenant with secure-only policy '${secureOnlyPolicyEnabled ? "enabled":"disabled"}'.`, 'success');
            this.props.tenantContext.refreshTenant();
            this.setState({
                newWanIpAddressValue: ""
            });
        }
        catch (err) {
            this.props.notificationContext.setNotification(`Updating tenant.`, `Error updating tenant with secure-only policy '${secureOnlyPolicyEnabled ? "enabled":"disabled"}'.  ${err.toDetailedMessage()}`, 'danger');
        }
    }

    executeSaveAuditTrailRetention = async (auditTrailRetentionDays) => {
        try
        {
            await apiCalls.tenantUpdateAuditTrailRetention(this.props.authenticationContext,
                auditTrailRetentionDays);

            this.props.notificationContext.setNotification(`Updating tenant.`, `Successfully updated tenant audit-trail retention to ${misc.daysToMonths(auditTrailRetentionDays)} months.`, 'success');
            this.props.tenantContext.refreshTenant();
            this.setState({
                newWanIpAddressValue: ""
            });
        }
        catch (err) {
            this.props.notificationContext.setNotification(`Updating tenant.`, `Error updating tenant audit-trail retention to ${misc.daysToMonths(auditTrailRetentionDays)} months.  ${err.toDetailedMessage()}`, 'danger');
        }
    }

    executeSaveCallRecording = async (callRecordingEnabled) => {
        try
        {
            await apiCalls.tenantUpdateCallRecording(this.props.authenticationContext,
                callRecordingEnabled);

            this.props.notificationContext.setNotification(`Updating tenant.`, `Successfully updated tenant with call recording '${callRecordingEnabled ? "enabled":"disabled"}'.`, 'success');
            this.props.tenantContext.refreshTenant();
            this.setState({
                newWanIpAddressValue: ""
            });
        }
        catch (err) {
            this.props.notificationContext.setNotification(`Updating tenant.`, `Error updating tenant with call recording '${callRecordingEnabled ? "enabled":"disabled"}'.  ${err.toDetailedMessage()}`, 'danger');
        }
    }

    redirectToAdminConsent = () => {
        this.props.authenticationContext.redirectToAdminConsent();
    }

    // ------------------------------
    // Rendering starts here...
    // ------------------------------
    render() {
        let headerRendered = <h1>Global settings</h1>

        if (this.state.isLoading) {
            return (
                <Container fluid>
                    <div>
                        {headerRendered}
                        {this.props.tenantContext.showWarningWhenDisabled()}
                        <p><em>Loading...</em></p>
                    </div>
                </Container>
            );
        }

        const showAuditTrailButtonRendered = this.props.tenantContext.isDistributor ? null :
            <>
                <hr/>
                <div className="mb-3">
                    <strong>Audit trail</strong>
                    <br/>
                    <Link to='/portal/audit-trail'><Button className="mt-1" color="primary">Show</Button></Link>
                </div>
            </>

        return (
            <Container fluid>
                {headerRendered}
                {this.props.tenantContext.showWarningWhenDisabled()}
                <div><br/></div>            
                <AdminConsent adminConsentGrantedFeatures={this.props.tenantContext.adminConsentGrantedFeatures}/>
                <hr/>
                <TenantAuditTrailConfig tenantInfo={this.props.tenantContext} onConfigChange={this.executeAuditTrailConfigChange}/>
                <hr/>               
                <TenantCommunicationTestScriptDownload/>
                <hr/>               
                <TenantCallForwardingConfig callForwardingEnabled={this.props.tenantContext.callForwardingEnabled} onCallForwardingChanged={this.executeSaveCallForwarding} />
                <hr/>               
                <TenantTrunkConfig tenantInfo={this.props.tenantContext} onTrunkSupportChange={this.executeTrunkSupportChange}/>
                <hr/>               
                <TenantSecurityPoliciesConfig tenantInfo={this.props.tenantContext} onSecureOnlyPolicyChange={this.executeSecureOnlyPolicyChange} />
                <hr/>               
                <TenantRecordingConfig tenantInfo={this.props.tenantContext} onCallRecordingChange={this.executeCallRecordingChange}/>
                <br/>
                <br/>
            </Container>
        );
    }
}

export const GlobalSettings = (props) => {
    const authenticationContext = useContext(AuthenticationContext);
    const notificationContext = useContext(NotificationContext);
    const tenantContext = useContext(TenantContext);
  
    return (
        <GlobalSettingsInternal authenticationContext={authenticationContext} notificationContext={notificationContext} tenantContext={tenantContext} {...props}/>
    )
}