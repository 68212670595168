import { Component, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import { createGroupOptions, createGroupOption, GroupedOption } from '../../../utils/selectHelper';
import { AuthenticationContext } from '../../../msal/AuthenticationContext';
import * as apiCalls from '../../../logic/apiCalls';
import { Group } from '../../../logic/apiCalls';

const guidRegexp = new RegExp('^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$');

type ModalAccessGroupAddInternalProps = {
    show: boolean,
    onHide: () => void,
    onAdd: (groupId: string) => void,
    alreadySelectedGroups: Group[],
    authenticationContext: any,
}

type ModalAccessGroupAddInternalState = {
    groupOptions: Array<GroupedOption>,
    isGroupDropdownOpen: boolean,
    selectedGroupOption: any,
    invalidGuidDetected: boolean,
    duplicateGuidDetected: boolean,
    incompleteGroupList: boolean
}

class ModalAccessGroupAddInternal extends Component<ModalAccessGroupAddInternalProps, ModalAccessGroupAddInternalState> {
    state: ModalAccessGroupAddInternalState = {
        groupOptions: [],
        isGroupDropdownOpen: false,
        selectedGroupOption: null,
        invalidGuidDetected: false,
        duplicateGuidDetected: false,
        incompleteGroupList: false
    }
   
    componentDidUpdate(prevProps: ModalAccessGroupAddInternalProps) {
        if (!prevProps.show && this.props.show) {
            this.setInitialValues();
        }
    } 

    setInitialValues = async () => {
        const groupCollection = await apiCalls.getAvailableGroups(this.props.authenticationContext);

        // Remove the groups that are already selected by this tenant, to prevent an attempt can be done to add the same group twice.
        const filteredGroups = groupCollection.groups.filter(group => !this.props.alreadySelectedGroups.some(item => item.azureGroupId === group.azureGroupId))

        const groupOptions = createGroupOptions(filteredGroups);

        this.setState(
            {
                groupOptions: groupOptions,
                invalidGuidDetected: false,
                duplicateGuidDetected: false,
                incompleteGroupList: !groupCollection.isComplete
            }
        );
    }

    handleSelectedGroupChange = (selectedGroupOption : any) => {
        let newselectedGroupOption = null;
        let invalidGuidDetected = false;
        let duplicateGuidDetected = false;

        if (selectedGroupOption?.label === selectedGroupOption?.value) {
            // User has manually entered a GUID.
            const createGroupId = selectedGroupOption.value.toString().trim();

            if (createGroupId.match(guidRegexp))
            {
                if (!this.props.alreadySelectedGroups.some(item => item.azureGroupId === createGroupId)) {
                    newselectedGroupOption = createGroupOption({ azureGroupId: createGroupId, azureGroupName: createGroupId, azureGroupType: '' });    
                } else {
                    duplicateGuidDetected = true;                
                }
            } else
            {
                invalidGuidDetected = true;
            }
        } else {
            // User has selected an entry from the list.
            newselectedGroupOption = selectedGroupOption;
        }

        this.setState(
            {
                selectedGroupOption: newselectedGroupOption,
                invalidGuidDetected: invalidGuidDetected,
                duplicateGuidDetected : duplicateGuidDetected
            }
        );  
    }

    handleHide = () => {
        this.setState(
            {
                invalidGuidDetected: false,
                duplicateGuidDetected : false
            }
        );  

        this.props.onHide();
    }

    handleAdd = () => {
        this.setState(
            {
                invalidGuidDetected: false,
                duplicateGuidDetected : false
            }
        ); 

        this.props.onAdd(this.state.selectedGroupOption.value);
    }

    render()
    {
        const formatCreateLabel = (inputValue : string) => `Use Entra ID: ${inputValue}`;

        const incompleteListWarningRendered = this.state.incompleteGroupList ? <p style={{color: 'red'}}>Warning: Could not retrieve all groups. Manually entering Microsoft Entra ID of the group might be required.</p> : null
        const invalidGuidDetectedRendered = this.state.invalidGuidDetected ? <p style={{color: 'red'}}>Invalid Entra ID entered. Entra ID should be formated like:<br/>xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx</p> : null
        const duplicateGuidDetectedRendered = this.state.duplicateGuidDetected ? <p style={{color: 'red'}}>Duplicate Entra ID entered.</p> : null

        return <>
            <Modal style={{ opacity: 1 }} show={this.props.show} onHide={this.props.onHide} data-backdrop="static" data-keyboard="false">
                <Modal.Header>
                    <Modal.Title>Select a group for portal access</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreatableSelect formatCreateLabel={formatCreateLabel} isSearchable options={this.state.groupOptions} onChange={this.handleSelectedGroupChange} placeholder='Select group or enter Microsoft Entra ID...' />
                    <div className='mt-3'>
                        {incompleteListWarningRendered}
                        {invalidGuidDetectedRendered}
                        {duplicateGuidDetectedRendered}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button color="secondary" onClick={this.handleHide}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={this.handleAdd}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>
        </>;
    }
}

// Wrap the wrapped component so that multiple contexts are available.
export const ModalAccessGroupAdd = (props: any) => {
    const authenticationContext = useContext(AuthenticationContext);
  
    return (
        <ModalAccessGroupAddInternal authenticationContext={authenticationContext} {...props}/>
    )
}