import { Component, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import { createSelectedGroupOptions, createGroupOptions, createGroupOption } from '../utils/selectHelper';
import { AuthenticationContext } from '../msal/AuthenticationContext';
import * as apiCalls from '../logic/apiCalls';

const guidRegexp = new RegExp('^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$');

class ModalEditAccessGroupEntriesInternal extends Component {
    state = {
        groupOptions: [],
        isGroupDropdownOpen: false,
        selectedGroupOptions: [],
        invalidGuidDetected: false,
        incompleteGroupList: false
    }
   
    componentDidUpdate(prevProps) {
        if (!prevProps.show && this.props.show) {
            this.setInitialValues();
        }
    }

    setInitialValues = async () => {
        const groupCollection = await apiCalls.getAvailableGroups(this.props.authenticationContext);
        const groupOptions = createGroupOptions(groupCollection.groups);
        const selectedGroupOptions = createSelectedGroupOptions(this.props.device.accessGroups);

        this.setState(
            {
                groupOptions: groupOptions,
                selectedGroupOptions: selectedGroupOptions,
                invalidGuidDetected: false,
                incompleteGroupList:  !groupCollection.isComplete
            }
        );
    }

    handleSelectedGroupChange = (selectedGroupOptions) => {
        let newselectedGroupOptions = [];
        let invalidGuidDetected = false;

        selectedGroupOptions.forEach(selectedGroupOption => {
            if (selectedGroupOption?.label === selectedGroupOption?.value) {
                const createGroupId = selectedGroupOption.value.toString().trim();

                if (createGroupId.match(guidRegexp))
                {
                    newselectedGroupOptions.push(createGroupOption({ azureGroupId: createGroupId, azureGroupName: createGroupId, azureGroupType: '' }));
                }
                else
                {
                    invalidGuidDetected = true;
                }
            } else {
                newselectedGroupOptions.push(selectedGroupOption)
            }
        });

        this.setState(
            {
                selectedGroupOptions: newselectedGroupOptions,
                invalidGuidDetected: invalidGuidDetected
            }
        );  
    }

    handleHide = () => {
        this.setState(
            {
                invalidGuidDetected: false
            }
        );  

        this.props.onHide();
    }

    handleUpdate = () => {
        this.setState(
            {
                invalidGuidDetected: false
            }
        ); 

        this.props.onUpdate(this.state.selectedGroupOptions);
    }

    render()
    {
        const formatCreateLabel = (inputValue) => `Use Entra ID: ${inputValue}`;

        const incompleteListWarningRendered = this.state.incompleteGroupList ? <p style={{color: 'red'}}>Warning: Could not retrieve all groups. Manually entering Microsoft Entra ID of the group might be required.</p> : null
        const invalidGuidDetectedRedered = this.state.invalidGuidDetected ? <p style={{color: 'red'}}>Invalid Entra ID entered. Entra ID should be formated like:<br/>xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx</p> : null

        return <>
            <Modal style={{ opacity: 1 }} show={this.props.show} onHide={this.props.onHide} data-backdrop="static" data-keyboard="false">
                <Modal.Header>
                    <Modal.Title>Select groups that have access to device: {this.props.device.deviceDescription}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreatableSelect formatCreateLabel={formatCreateLabel} isMulti isSearchable value={this.state.selectedGroupOptions} options={this.state.groupOptions} onChange={this.handleSelectedGroupChange} placeholder='Select group or enter Microsoft Entra ID...' />
                    <div className='mt-3'>
                        {incompleteListWarningRendered}
                        {invalidGuidDetectedRedered}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button color="secondary" onClick={this.handleHide}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={this.handleUpdate}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        </>;
    }
}

// Wrap the wrapped component so that multiple contexts are available.
export const ModalEditAccessGroupEntries = (props) => {
    const authenticationContext = useContext(AuthenticationContext);
  
    return (
        <ModalEditAccessGroupEntriesInternal authenticationContext={authenticationContext} {...props}/>
    )
}