export const intMaxValue = 2147483647; 

export function compareCaseInsensitive(a: string, b: string) {
    return a.toLowerCase().localeCompare(b.toLowerCase());
}

export function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

/** Method compares if every property of the first object is the same in the second object.
 *  The second object may have more properties than the first object which don't affect the result of this method.
  */
 export const oneWayCompare = (obj1: any, obj2: any) => {
    return Object.keys(obj1).every(key => obj1[key] === obj2[key]);
}

export function intLimitedSum(numberA: number, numberB: number, limit: number = intMaxValue) {
    if (numberA === limit || numberB === limit || numberA + numberB > limit) {
        return limit;
    }
    return numberA + numberB;
}

export function intToString(number: number, infinityMarking: string = "∞") {
    return number === intMaxValue ? infinityMarking : String(number);
}

export function ip2int(ip : string) : number {
    return ip.split('.').reduce(function(ipInt, octet) { return (ipInt<<8) + parseInt(octet, 10)}, 0) >>> 0;
}

export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function trimRight(input: string, charlist: string = '\\s') {
    return input.replace(new RegExp("[" + charlist + "]+$"), "");
};

export function trimLeft(input: string, charlist: string = '\\s') {
    return input.replace(new RegExp("^[" + charlist + "]+"), "");
};

function range(size: number, startAt: number = 0) {
    return [...Array(size).keys()].map(i => i + startAt);
}

function characterRange(startChar: string, endChar: string) {
    return String.fromCharCode(...range(endChar.charCodeAt(0) -
            startChar.charCodeAt(0), startChar.charCodeAt(0)));
}

const randomCharacters = characterRange('a', 'z') + 
                         characterRange('A', 'Z') + 
                         characterRange('0', '9');

export function generateRandomString(length: number) {
    const randomValues = new Uint8Array(length);
    window.crypto.getRandomValues(randomValues);

    const outputCharArray = [];
    for (const value of randomValues) {
        const character = randomCharacters[value % randomCharacters.length];
        outputCharArray.push(character);
    }
    
    return outputCharArray.join('');
}

export function urlCombine(...urlFragments: string[]) {
    let url = urlFragments[0];

    for (let i = 1; i < urlFragments.length; i++) {
        const urlFragment = urlFragments[i];

        url = trimRight(url, '/') + '/' + trimLeft(urlFragment, '/');
    }

    return url;
}

export function getBaseUriWithoutParameters() {
    let url = document.getElementById('root')?.baseURI;
    if (!url) {
        throw new Error("Could not determine base URI.");
    }

    if (url.indexOf("?") > -1) {
        url = url.substr(0, url.indexOf("?"));
    }   

    return url;
}

export function daysToMonths(days: number) {
    return Math.round(days / 31);
}

export function monthsToDays(months: number) {
    return Math.round(months * 30.41667);
}